<template>
  <div class="body fixed mobile">
    <section class="header store_register">
      <div class="flexH width">
        <a
          class="icon iconButton third"
          @click.prevent="$router.push('/profile')"
        >
          <font-awesome-icon icon="fa-solid fa-chevron-left" size="lg" />
        </a>
      </div>
      <span class="fL nowrap">{{ $t("setting") }}</span>
      <div class="flexH width right">
        <!-- <a class="textButton fM third">草稿暫存</a> -->
      </div>
    </section>
    <div class="main bg-white">
      <ul>
        <li class="flex justify-between text-lg pt-12p pb-12p">
          <a
            href="/notification-setting"
            class="flex justify-between flex-1 items-center"
          >
            <span> 通知設定 </span>
            <font-awesome-icon
              icon="fa-solid fa-chevron-right"
              color="#6b2df5"
            />
          </a>
        </li>
        <li class="flex justify-between text-lg pt-12p pb-12p">
          <div class="flex justify-between flex-1 items-center" @click="updateApp">
            <span> 檢查更新 </span>
            <font-awesome-icon
              icon="fa-solid fa-chevron-right"
              color="#6b2df5"
            />
          </div>
        </li>
        <li class="flex justify-between text-lg pt-12p pb-12p">
          <a class="w-full flex justify-between" @click.prevent="$router.push('/static?type=terms')">

            <span> 服務條款與隱私條款 </span>
            <font-awesome-icon icon="fa-solid fa-chevron-right" color="#6b2df5" />
          </a>
        </li>
        <li class="flex justify-between text-lg pt-12p pb-12p">
          <a class="w-full flex justify-between" href="https://planning.yunlin.gov.tw/News.aspx?n=11486&sms=16781">
            <span> 關於雲林幣扭一下2.0 </span>
            <font-awesome-icon icon="fa-solid fa-chevron-right" color="#6b2df5" />
          </a>
        </li>
        <li class="flex justify-between text-lg pt-12p pb-12p">
          <span> 刪除雲林幣扭一下帳號 </span>
          <font-awesome-icon icon="fa-solid fa-chevron-right" color="#6b2df5" />
        </li>
        <li class="flex justify-between text-lg pt-12p pb-12p" @click="logout()">
          <span > 登出 </span>
          <font-awesome-icon icon="fa-solid fa-chevron-right" color="#6b2df5" />
        </li>
      </ul>
    </div>
    <section v-if="showConfirm" class="popup">
      <div class="popBody">
        <section class="popHeader">
          <!-- <span class="fL">Title</span> -->
        </section>
        <section class="popMain">
          <div>
            {{ $t("alert") }}
          </div>
          <span class="text-center mt-2">{{ $t("clean-cache-desc") }}</span>
        </section>
        <section class="popFooter">
          <button
            class="button rounded white cancel"
            @click="showConfirm = false"
          >
            {{ $t("cancel") }}
          </button>
          <a class="button submit rounded">
            <span class="fM">{{ $t("confirm") }}</span>
          </a>
        </section>
      </div>
    </section>
    <AppUpdateDialog v-if="showDialog" @close="() => showDialog=false" />
  </div>
</template>

<script>
import AppUpdateDialog from "@/components/Dialog/AppUpdateDialog.vue";

export default {
  name: "Settings",
  components: {
    AppUpdateDialog,
  },
  data() {
    return {
      showConfirm: false,
      showDialog: false,
      appHost: process.env.VUE_APP_HOST + process.env.BASE_URL,
    };
  },
  mounted() {
    if (sessionStorage.getItem('reloadAlert') === 'true') {
      // 顯示 alert
      this.showDialog = true;
      
      // 移除標記，防止後續的頁面重新加載還觸發 alert
      sessionStorage.removeItem('reloadAlert');
    }
  },
  methods: {
    logout() {
      qcsso
        .logout({
          logoutRedirectUri: this.appHost.substring(0, this.appHost.length - 1),
        })
        .then(() => window.Yunlin?.postMessage(JSON.stringify({ type: "logout" })))
        .catch((err) => console.log(err)).finally(() => {
          sessionStorage.removeItem('hasSetLogin')
          console.log("JS Interface", { type: "logout" })
        })
    },
    updateApp() {
      sessionStorage.setItem('reloadAlert', 'true');
      window.location.reload();
    }
  }
  // beforeRouteEnter(to, from, next) {
  //   // called before the route that renders this component is confirmed.
  //   // does NOT have access to `this` component instance,
  //   // because it has not been created yet when this guard is called!
  //   console.log(to, from);
  //   next((vm) => {
  //     let that = vm;
  //     console.log("user: ", that.user);
  //     if (that.user && that.user.userId) {
  //       vm.getUserPoints()
  //         .then((res) => {
  //           that.$store.commit("updateUserPoints", res);
  //         })
  //         .catch((err) => {
  //           console.log("err: ", err);
  //           next("/error");
  //         });
  //       vm.$store.commit(
  //         "updateUserInfo",
  //         Object.assign({}, vm.userInfo, {
  //           myReferralCode: vm.user.user.myReferralCode,
  //         })
  //       );
  //       vm.myReferralCode = vm.user.user.myReferralCode;
  //     } else {
  //       next("/error");
  //     }
  //   });
  // },
};
</script>
<style scoped>
ul {
  padding: 0;
}

li {
  list-style: none;
  border-bottom: 1px solid #d3d3d3;
  padding-right: 8px;
}

.popup {
  display: flex !important;
}

.cancel {
  border: 1px solid #6b2df5;
}
</style>
